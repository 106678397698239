<template>
  <v-container fluid class="pa-0 ma-0">
    <v-carousel
      cycle
      :interval="4000"
      hide-delimiter-background
      show-arrows-on-hover
      transition="fade-transition"
      :height="carouselHeight"
    >
      <v-carousel-item
        v-for="(item, i) in items"
        :key="i"
      >
        <v-img
          :src="item.src"
          class="carousel-image"
          cover
        />
      </v-carousel-item>
    </v-carousel>
  </v-container>
</template>

<script>
export default {
  name: 'SliderComponent',

  data() {
    const currentFullDate = this.getCurrentFullDate();
    return {
      currentFullDate,
      items: [
        {
          src: `${process.env.VUE_APP_BASE_IMG}/sliders/slider1_010425.png?${currentFullDate}`,
        },
        {
          src: `${process.env.VUE_APP_BASE_IMG}/sliders/slider2_010425.png?${currentFullDate}`,
        },
        {
          src: `${process.env.VUE_APP_BASE_IMG}/sliders/slider3_010425.png?${currentFullDate}`,
        },
      ],
    };
  },

  computed: {
    carouselHeight() {
      // Altura adaptativa por breakpoint
      const bp = this.$vuetify.breakpoint.name;
      if (bp === 'xs' || bp === 'sm') return '300px';
      if (bp === 'md') return '400px';
      if (bp === 'lg') return '550px';
      return '600px'; // xl y por defecto
    },
  },

  methods: {
    getCurrentFullDate() {
      const today = new Date();
      const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
      return today.toLocaleDateString('es-ES', options);
    },
  },
};
</script>

<style scoped>
.carousel-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
