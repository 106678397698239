<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <div class="text-h5 font-weight-medium my-1 hidden-md-and-up">
          Listado de citas médicas realizadas.
        </div>
        <div class="text-h5 font-weight-medium hidden-sm-and-down hidden-lg-and-up">
          Listado de citas médicas realizadas.
        </div>
        <div class="text-h5 hidden-md-and-down">
          Listado de citas médicas realizadas.
        </div>
        <div class="gris--text">Utiliza tus citas médicas y cuida de tu familia
          <strong>familia</strong> 
          <v-chip v-if="this.numerocitas > 0 && this.estado === 1" class="ma-2" color="green darken-3" outlined small>
            <v-avatar left>
              <v-icon>mdi-checkbox-marked-circle</v-icon>
            </v-avatar>
            Citas disponibles {{ this.numerocitas }}
          </v-chip>
          <v-chip v-else class="ma-2" color="red darken-3" outlined small>
            <v-avatar left>
              <v-icon>mdi-alert-circle</v-icon>
            </v-avatar>
            Citas disponibles {{ this.numerocitas }}
          </v-chip> recuerda que puedes <strong>agregar citas</strong>  a precio especial, aprovecha al máximo tu membresía, contacta a tu agente o comunicate con MediCover.
        </div>
      </v-col>
    </v-row>
    <v-row v-show="this.numerocitas > 0 && this.estado === 1"  no-gutters> 
            <v-col cols="12" xs="12" sm="6" md="3" lg="3" xl="3">
              <select-especialidades-component @value-changed="updateEsp"></select-especialidades-component>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="3" lg="3" xl="3">
              <medicos-component v-show="especialidad" @value-changed="updateMed" 
              :especialidadValue="especialidad"></medicos-component>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="3" lg="3" xl="3">
              <select-beneficiados-membresia v-show="medico" @value-changed="updateBen" 
              :membresia="this.getIdTarjeta"></select-beneficiados-membresia>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="3" lg="3" xl="3">
                <v-btn v-show="medico" color="azulfuerte" @click="crearCita" dark>
                  <v-icon>mdi-plus</v-icon> GENERAR PASE </v-btn>
            </v-col>
          </v-row>
          <table-read-citas-medicas 
            :reloadTrigger="this.reloadTrigger"
            :Membresia="this.getIdTarjeta"
          ></table-read-citas-medicas>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import EndPointNet from "@/functions/axios/axios-bearer";
//import SelectEspecialidadesComponent from "@/components/SelectEspecialidadesComponent.vue";
//import MedicosComponent from "@/components/MedicosComponent.vue";
//import BenediciadosComponent from "@/components/user/BeneficiadosComponent.vue";


export default {
  name: "CitasCliente",
  created() {
    this.getNumCitas()
  },
  components: {
    SelectEspecialidadesComponent: () => import('@/components/SelectEspecialidadesComponent.vue'),
    MedicosComponent: () => import('@/components/MedicosComponent.vue'),
    SelectBeneficiadosMembresia: () => import('@/components/selectBeneficiadosMembresia.vue'),
    TableReadCitasMedicas: () => import('@/components/TableReadCitasMedicas.vue')
    //SelectEspecialidadesComponent, MedicosComponent, BenediciadosComponent
  },
  data: () => ({
    reloadTrigger: 0,
    numerocitas: 0,
    estado: 0,
    nuevacita: null,
    especialidad: '',
    medico: '',
    beneficiado: '',
    valid: false,
    isModal: false,
    list: [],
    search: "",
    headers: [
      //{ text: "Id", value: "Id", sortable: false },
      { text: "Folio", value: "Folio", sortable: false },
      { text: "Parentesco", value: "Parentesco", sortable: false },
      { text: "Beneficiado", value: "Beneficiado", sortable: false },
      { text: "Especialidad", value: "Especialidad", sortable: true },
      { text: "Medico", value: "Medico", sortable: true },
      { text: "Estatus", value: "FecCierre", sortable: true },
      { text: "Creada", value: "created_at", sortable: true },
      { text: "Opciones", value: "ops", sortable: false },
    ],
  }),
  computed: {
    ...mapGetters(["getIdTarjetaTxt", "getIdTarjeta"]),
  },
  methods: {
    updateEsp(esp) {
      this.especialidad = esp
    },
    updateMed(med) {
      this.medico = med.IdMedico
    },
    updateBen(ben) {
      this.beneficiado = ben.Id
    },
    GenerarPase() {
      this.isModal = true
    },
    crearCita() {

      if (this.getIdTarjeta && this.especialidad  && this.medico  && this.beneficiado) {
        this.$loading(true);
        EndPointNet(`membresia/citamedica`, "POST",
          {
            idMem: this.getIdTarjeta,
            idEsp: this.especialidad,
            idMed: this.medico,
            idBen: this.beneficiado
          }
        )
          .then((response) => {
            this.especialidad = ''
            this.medico = ''
            this.beneficiado = ''
            this.isModal = false
            if (response.status === 200) {
              this.nuevacita = response.data;
              this.reloadTrigger++;
              this.getNumCitas()
              // this.search = response.data

              this.$loading(false);
              this.$swal({
                position: 'top-end',
                title: 'Correcto',
                text: 'Su pase médico se genero correctamente',
                icon: 'success',
                confirmButtonText: 'Cerrar',
                showCloseButton: false,
                showConfirmButton: false,
                timer: 1500
              })
            } else {
              this.isModal = false
              this.$loading(false);
              this.$swal({
                title: 'Infomación',
                text: 'No tienes pases sificientes puedes hacer una recarga de 5 a un costo especial contacta a tu agente.',
                icon: 'warning',
                confirmButtonText: 'Aceptar'
              });
            }
          })
          .catch(() => {
            this.$loading(false);
            this.especialidad = ''
            this.medico = ''
            this.beneficiado = ''
            this.isModal = false
            this.$swal({
              // position: 'top-end',
              title: 'Error',
              text: `Hemos detectado un error, el cual ya ha sido reportado. Por favor, intenta nuevamente más tarde.`,
              icon: 'error',
              confirmButtonText: 'Cerrar',
              showCloseButton: false
              // timer: 1500,
            })
          });
      } else {
        this.especialidad = ''
        this.medico = ''
        this.beneficiado = ''
        this.$swal({
          // position: 'top-end',
          title: 'Infomación',
          text: `Selecciona una especialidad, un médico y para quien será el pase.`,
          icon: 'warning',
          confirmButtonText: 'Cerrar',
          showCloseButton: false
          // timer: 1500,
        })
      }
    },
    cerrar() {
      this.especialidad = ''
      this.medico = ''
      this.beneficiado = ''
      this.isModal = false
    },
    getNumCitas() {
      this.$loading(true);
      EndPointNet(`Membresia/Numerocitas${this.getIdTarjeta}`, "GET")
        .then((response) => {
          if (response.status === 200) {
            this.estado = response.data[0].Estatus
            this.numerocitas = response.data[0].NumCitas
            this.$loading(false);
          }
        })
        .catch(() => {
          this.$loading(false);
        });
    },
  },
};
</script>
