<template>
  <v-container>
    <div class="responsive-text-h1 text-center">
      Seguridad y confianza en nuestra red hospitalaria
    </div>
    <v-row>
      <v-col md="0" class="hidden-sm-and-down" sm="0" xs="0" lg="1" xl="1">
      </v-col>
      <v-col md="2" sm="6" xs="6" lg="2" xl="2">
        <v-img src="https://medicover.mx/img/LogHospitalAngeles.webp"
          :style="`max-width:${logoProperties.hospital}`"></v-img>
      </v-col>
      <v-col md="2" sm="6" xs="6" lg="2" xl="2">
        <v-img src="https://medicover.mx/img/LogoST.svg" :style="`max-width:${logoProperties.hospital}`"></v-img>
      </v-col>
      <v-col md="2" sm="2" xs="2" lg="2" xl="2">
        <v-img src="https://medicover.mx/img/Logolabene.png" :style="`max-width:${logoProperties.hospital}`"></v-img>
      </v-col>
      <v-col md="2" sm="2" xs="2" lg="2" xl="2">
        <v-img src="https://medicover.mx/img/logohs.png" :style="`max-width:${logoProperties.hospital}`"></v-img>
      </v-col>
      <v-col md="2" sm="2" xs="2" lg="2" xl="2">
        <v-img src="https://medicover.mx/img/logolomas.png" :style="`max-width:${logoProperties.hospital}`"></v-img>
      </v-col>
    </v-row>
    <div class="responsive-text-h1 text-center">
      Sobre nosotros
    </div>
    <v-card>
      <v-tabs v-model="tab" color="deep-purple-accent-4" align-tabs="center">
        <v-tab :value="1"></v-tab>
        <v-tab :value="2">Misión</v-tab>
        <v-tab :value="3">Visión</v-tab>
        <v-tab :value="4">Historia</v-tab>
      </v-tabs>
      <v-window v-model="tab">
          <v-container fluid>
            <p class="text-justify text--secondary" v-if="tab == 1">
              "Generar Servicios integrales de protección de salud para nuestros clientes
              con innovación y creatividad; con la mejor relación calidad, costo-beneficio, a través de la excelencia,
              efectividad y desarrollo de nuestros colaboradores; buscando hacer socios de negocios a nuestros principales
              proveedores, creando valor para los accionistas y promoviendo una cultura preventiva en la sociedad en
              general"
            </p>
            <p class="text-justify text--secondary" v-if="tab == 2">
              "Ser la mejor opción del mercado en servicios de acceso y protección a la salud"
              <br/>
              <br/> <v-icon size="28px" class="azul--text">check</v-icon>Trabajo en Equipo
              <br/> <v-icon size="28px" class="azul--text">check</v-icon>Cultura Emprendedora
              <br/> <v-icon size="28px" class="azul--text">check</v-icon>Confiabilidad
              <br/> <v-icon size="28px" class="azul--text">check</v-icon>Compromiso
            </p>
            <p  class="text-justify text--secondary" v-if="tab == 3">
              Medicover® es una marca registrada que forma parte de los activos de Multiservicios
              Profesionales de Especialidades, S.C., Centro de Servicio Asegurador.
              Si bien, la marca Medicover® surge formalmente en el año 2008, desde el año 2006
              nace con una propuesta de servicios única en el mercado consistente en ofrecer
              medicina pre pagada a través de las denominadas membresías de servicios médicos,
              específicamente como COMPLEMENTO a las coberturas que por algún motivo carecen
              las pólizas de Gastos Médicos Mayores de las compañías aseguradoras
              en el mercado mexicano.
              En el año 2006 se inicia con el servicio de CONSULTA EXTERNA con médicos especialistas de reconocido
              prestigio en el mercado, formando la primera RED de prestadores de servicios MEDICOVER®. Posteriormente en
              el año 2008 se ve la necesidad de iniciar una Alianza Estratégica con proveedores hospitalarios a efecto de
              que se brinde a los usuarios los servicios de atención médica de URGENCIA por la pérdida de la salud a causa
              de una ENFERMEDAD o ACCIDENTE y cuya resolución se de en un lapso menor a las 24 horas. En el año 2010 se
              inicia la cobertura DENTAL también con una RED de profesionistas dentales en el área de la ortodoncia,
              endodoncia, odontopediatría, cirugía ortognática y maxilofacial y en el 2011 se inicia con los servicios
              VISUALES con el surtido de lentes de contacto duros, blandos o desechables o de armazón. Actualmente se está
              trabajando para que próximamente podamos ofrecer los servicios del surtido de recetas médicas para nuestros
              agremiados con el servicio denominado FARMACIA a tu casa.
              Cabe señalar que durante éstos años de servicio, se han blindado nuestras membresías MEDICOVER® con
              descuentos en Estudios de Laboratorio e Imagenología, y tarifas preferentes en caso de que nuestros usuarios
              necesiten hospitalizarse en la RED de prestadores de éstos servicios. Para nosotros es un orgullo ser los
              primeros en brindar a nuestros usuarios, servicios innovadores en materia del cuidado de la salud, siendo la
              empresa líder en éste segmento utilizando el concepto de membresías de servicios médicos.
            </p>
            
          </v-container>
      </v-window>
    </v-card>
  </v-container>
</template>
  
<script>
export default {
  name: 'SliderComponent',
  data: function () {
    return {
      tab: 1,
      items: [
        {
          src: `${process.env.VUE_APP_BASE_IMG}/sliders/slider0.png`,
        },
        {
          src: `${process.env.VUE_APP_BASE_IMG}/sliders/slider1.png`,
        },
        {
          src: `${process.env.VUE_APP_BASE_IMG}/sliders/slider2.png`,
        },
        {
          src: `${process.env.VUE_APP_BASE_IMG}/sliders/slider3.png`,
        }
      ],
    };
  },
  methods: {
    addItem (item) {
        const removed = this.items.splice(0, 1)
        this.items.push(
          ...this.more.splice(this.more.indexOf(item), 1),
        )
        this.more.push(...removed)
        this.$nextTick(() => { this.currentItem = 'tab-' + item })
      }
  },
  computed: {
    logoProperties() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return {
            width: '100%',
            height: '500px',
            hospital: '100px',
            hospitalgrande: "60px"
          };
        case 'sm':
          return {
            width: '100%',
            height: '300px',
            hospital: '100px',
            hospitalgrande: "60px"
          };
        case 'lg':
          return {
            width: '100%',
            height: '300px',
            hospital: '150px',
            hospitalgrande: "50%"
          };
        case 'xl':
          return {
            width: '100%',
            height: '350px',
            hospital: '150px',
            hospitalgrande: "50%"
          };
        default:
          return {
            width: '100%',
            hospitalgrande: "50%"
          };
      }
    },
    Slider() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return {
            height: '300px',
          };
        case 'sm':
          return {
            height: '350px',
          };
        case 'lg':
          return {
            height: '400px',
          };
        case 'xl':
          return {
            height: '550px',
          };
        default:
          return {
            height: '400px',
          };
      }
    },
  },
}
</script>